angular.module('MyHippoProducer.Controllers').directive('mortgageInformationSection', function () {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'pages/policy-details/sections/mortgage-information/mortgage-information.html',
        controller: function ($scope, WorkflowAdapter, PolicyService, $mdDialog) {
            $scope.policyInfo = PolicyService.policyInfo;
            $scope.checkoutData = $scope.policyInfo.checkout_data;
            $scope.escrowCheckout = WorkflowAdapter.getAllFieldsForSection('checkout_page', 'escrow_section');
            $scope.isEditMode = PolicyService.isEditMode;

            $scope.editMortgage = (mortgage) => {
                const lenderModal = $mdDialog.lenderModal({
                    locals: { mortgage }
                });
                $mdDialog.show(lenderModal);
            };

            $scope.deleteMortgage = (mortgage) => {
                const mortgages = _.get(PolicyService.policyInfo, 'property_data.mortgages.details');
                _.remove(mortgages, (m) => m.id === mortgage.id);
            };

            $scope.addMortgage = () => {
                const mortgages = _.get(PolicyService.policyInfo, 'property_data.mortgages.details');
                const newMortgageType = ['first', 'second', 'third'].find(type => !mortgages.find(m => m.type == type));
                if (newMortgageType) {
                    const newMortgage = {
                        id: `new${new Date().getTime()}`,
                        zip: '',
                        city: '',
                        name: '',
                        type: newMortgageType,
                        state: '',
                        billed: false,
                        number: '',
                        street: '',
                    };
                    const lenderModal = $mdDialog.lenderModal({
                        locals: { mortgage: newMortgage }
                    });

                    if (mortgages.length < 2) {
                        $mdDialog.show(lenderModal).then(() => {
                            mortgages.push(newMortgage);
                        });
                    } else if (mortgages.length === 2) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Please Call Customer Support')
                                .textContent('Only allow a total of two mortgage lenders. If additional lenders are needed, please call customer support.')
                                .ariaLabel('Please Call Customer Support')
                                .ok('Close')
                        );
                    }
                }
            };
        }
    };
});
